import axios from 'axios';
import firebase from 'firebase';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

import withAuthorization from '../../utils/Session/withAuthorization';

import { NavBar } from '../../components/NavBar';
import { SideBar } from '../../components/SideBar';

import api from '../../../services/api';
import { normalizeLowerCase } from '../../../utils/normalizeLowerCase';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.toastId = null;

    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      authUser: {},
      actionId: {},
      formValid: false,
      formErrors: {},
      isLoading: false,
      countSeasons: 0,
      search: {},
      loadingStateList: false,
      loadingRegionalList: false,
      creatingAction: false,
      stateList: [],
      cityList: [],
      regionalList: [],
      isUploading: false,
      errorForm1: false,
      errorTime1: false,
      errorTimeMsg1: '',
      msg1: '',
      progress: 0,
      awardsModalIsOpen: false,
      extraPointsModalIsOpen: false,
      regionalIsEmpty: false,
      questions: [],
      questionErrors: [],
      questionFormIsComplete: true,
      formData: {
        actionTitle: '',
        pointsAward: '',
        physicalAwards: '',
        numberOfWinners: '',
        startDate: '',
        startDateTime: '',
        startDateEpoch: '',
        endDate: '',
        endDateTime: '',
        endDateEpoch: '',
        season: {},
        files: {},
        videoURL: '',
        callToAction: '',
        callActionText: '',
        contentURL: '',
        countDownVisible: true,
        shareButtonsVisible: true,
        startDateVisible: true,
        description: '',
        rulesOfAction: '',
        scope: '',
        scopeFieldName: 'Unidade',
        scopeValues: [],
        quizChoice: 'true',
        selectedStates: [],
        selectedCities: [],
        // landingPagePreviewPoint: "",
        // leadRegistration: "",
        // salesConversion: "",
        // conversionType: "",
        conversionFieldName: '',
        hasAwards: 'false',
        awardsList: [
          {
            startAt: 1,
            endAt: 2,
            premiumDescription: '',
            imageURL: '',
            isUploading: false,
          },
        ],
        extraPointsList: [
          {
            startAt: 1,
            endAt: 2,
            points: 0,
          },
        ],
      },
      formButtonPressed: false,
      phase1Uncompleted: false,
      phase2Uncompleted: false,
    };

    return initialState;
  }

  async componentDidMount() {
    const { clientData } = this.context;
    this.getQuiz('624b7789aec71dd676dcf877');
    const season = {
      id: false,
      seasonName: 'defaultSeason',
    };
    this.handleFormDataChange('season', season);

    this.phase2IsUncompleted();
  }

  async getStateList() {
    return axios
      .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then((states) => {
        const sortedStates = _.sortBy(states.data, 'nome');
        this.setState({ stateList: sortedStates });
      })
      .catch((error) => {
        console.log('getStateList error:', error);
      });
  }

  async getCityList(statesArr) {
    if (!statesArr || !statesArr.length) return;

    const { stateList } = this.state;

    const newCitiesarr = [];

    const newCitesPromises = statesArr.map(async (stateObj) => {
      const chosenState = _.find(
        stateList,
        (estado) => estado.sigla === stateObj.value,
      );

      const response = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateObj.value}/municipios`,
      );

      const stateCities = response.data;

      _.forEach(stateCities, (cityObject) => {
        const data = {
          city: `${cityObject.nome} - ${chosenState.sigla}`,
          state: chosenState.sigla,
        };
        newCitiesarr.push(data);
      });
    });

    Promise.all(newCitesPromises).then(() =>
      this.setState({ cityList: newCitiesarr }),
    );
  }
  getQuiz(actionId) {
    api
      .get(`/auth/getQuiz`)
      .then((res) => {
        const formData = res.data.user.actionData;
        console.log(formData);
        this.setState(
          {
            isLoading: false,
            formData,
            questions: formData.questions,
          },
          () => {
            this.validateForm();
          },
        );
      })
      .catch((error) => {
        console.log('getActionById error:', error);
      });
  }

  async createAction() {
    const { formData } = this.state;
    this.setState({ creatingAction: true }, async () => {
      this.toastId = toast.info('Atualizando perguntas... Aguarde', {
        autoClose: false,
      });

      const { startDate, startDateTime, endDate, endDateTime } = formData;

      formData.startDateEpoch = moment(
        `${startDate} ${startDateTime}`,
        'YYYY-MM-DD HH:mm',
      ).valueOf();
      formData.endDateEpoch = moment(
        `${endDate} ${endDateTime}`,
        'YYYY-MM-DD HH:mm',
      ).valueOf();

      formData.actionType = 'quiz';
      formData.questions = this.state.questions;
      const parameters = {
        actionData: formData,
      };

      const res = await api
        .post('/auth/updateQuiz', parameters)

        .then((res) => {
          this.setState({ creatingAction: false }, () => {
            toast.update(this.toastId, {
              render: 'Ação criada com sucesso!',
              type: toast.TYPE.SUCCESS,
              autoClose: true,
            });

            // this.props.history.goBack();
          });

          // setTimeout(() => {
          //   this.props.history.goBack();
          // }, 3000);
        })
        .catch((error) => {
          this.setState({ creatingAction: false }, () => {
            toast.update(this.toastId, {
              render: 'Não foi possivel criar a ação!',
              type: toast.TYPE.ERROR,
              autoClose: true,
            });
          });
        });
    });
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    if (value && value.length <= 0) {
      fieldValidationErrors[fieldName] = this.translate(
        'Você precisa preencher esse campo.',
      );
    } else {
      fieldValidationErrors[fieldName] = '';
    }
    this.setState({ formErrors: fieldValidationErrors }, () => {
      this.validateForm();
    });
  }

  validateForm() {
    let emptyFieldsCount = 0;
    const emptyFields = [];
    const { formData } = this.state;
    _.each(this.state.formData, (input, inputKey) => {
      if (!input && inputKey !== 'usersCount' && inputKey !== 'hasAwards') {
        emptyFields.push(inputKey);
        emptyFieldsCount++;
      }

      if (
        inputKey === 'scopeValues' &&
        formData.scopeValues.length <= 0 &&
        formData.scope === 'regional'
      ) {
        emptyFields.push(inputKey);
        emptyFieldsCount++;
      }
    });
    console.log('### empt', emptyFields);
    this.setState({ formValid: emptyFieldsCount === 0 });
  }

  handleUserInput(e) {
    const { name, value } = e.target;
    this.handleFormDataChange(name, value);
    console.log('name, value', name, value);
  }

  handleDateChangeValid() {
    const {
      startDate,
      endDate,
      startDateTime,
      endDateTime,
      season,
    } = this.state.formData;

    // Travamento entre datas da temporada e do exchange
    if (startDate !== '' && endDate !== '' && startDate > endDate) {
      this.setState({
        errorForm1: true,
        msg1: 'Início da publicação deve ser antes do fim.',
      });
      document.getElementById('handleDateChangeValid-1').className =
        'help-block';
    } else {
      this.setState({ errorForm1: false });
      document.getElementById('handleDateChangeValid-1').className =
        'help-block hidden';
    }

    // Validando se ação começa e termina entre a temporada
    if (season.id !== false) {
      if (
        startDate !== '' &&
        season.startDate !== '' &&
        startDate <= season.startDate
      ) {
        this.setState({
          errorForm1: true,
          msg1:
            'A publicação deve começar pelo menos 1 dia após o início da Temporada.',
        });
        document.getElementById('handleDateChangeValid-1').className =
          'help-block';
      }

      if (
        endDate !== '' &&
        season.endDate !== '' &&
        endDate >= season.endDate
      ) {
        this.setState({
          errorForm1: true,
          msg3:
            'A publicação deve terminar pelo menos 1 dia antes do fim da Temporada.',
        });
        document.getElementById('handleDateChangeValid-3').className =
          'help-block';
      } else {
        this.setState({ errorForm1: false });
        document.getElementById('handleDateChangeValid-3').className =
          'help-block hidden';
      }
    }

    // Validando Horas de Início e Fim da temporada
    if (
      startDate >= endDate &&
      startDate !== '' &&
      startDateTime !== '' &&
      endDateTime !== ''
    ) {
      const startHour = startDateTime.split(':');
      const endtHour = endDateTime.split(':');

      // Datas iguais
      switch (startDate === endDate) {
        case true:
          if (startHour[0] > endtHour[0]) {
            this.setState({
              errorTime1: true,
              errorTimeMsg1: 'Hora final não pode ser inferior a hora inicial.',
            });
          } else if (
            startHour[0] === endtHour[0] &&
            startHour[1] >= endtHour[1]
          ) {
            this.setState({
              errorTime1: true,
              errorTimeMsg1:
                'Horário inicial deve ser inferior ao horário final.',
            });
          } else {
            this.setState({ errorTime1: false, errorTimeMsg1: '' });
          }
          break;
        default:
      }
    } else {
      this.setState({ errorTime1: false, errorTimeMsg1: '' });
    }
  }

  handleDateChange(e) {
    const { name, value } = e.target;
    const isValidDate = moment(value).isValid();
    if (!isValidDate) {
      return;
    }

    const epochDate = moment(value).valueOf();
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
          [`${name}Epoch`]: epochDate,
        },
      }),
      () => {
        this.validateField(name, value);
      },
    );
  }

  handleFormDataChange(name, value) {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      async () => {
        this.validateField(name, value);

        this.setState({
          phase1Uncompleted: this.phase1IsUncompleted(),
          phase2Uncompleted: await this.phase2IsUncompleted(),
        });
      },
    );
  }

  handleSeasonChange(e) {
    const { value } = e.target;
    const { seasons } = this.state;

    const actionSeason = _.find(seasons, (season) => {
      return season.id === value;
    });

    this.handleFormDataChange('season', actionSeason);
  }

  handleScopeChange(e) {
    this.handleUserInput(e);
  }

  handleStateChange(e) {
    this.handleFormDataChange('selectedStates', e);

    this.setState({ cityList: [] }, () => this.getCityList(e));
  }

  handleCityChange(e) {
    this.handleFormDataChange('selectedCities', e);
  }

  renderStateSelection() {
    const { clientData } = this.context;
    const { stateList, formData } = this.state;
    const { selectedStates } = formData;

    if (formData.scope !== 'regional') return <></>;
    if (!stateList || !stateList.length) return <></>;

    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="selectedStates">
          Estados que serão impactados: <code>*</code>
        </label>
        <Select
          isMulti
          name="selectedStates"
          placeholder="Selecione quais estados deseja atingir ..."
          options={_.map(stateList, (estado) => ({
            value: estado.sigla,
            label: estado.nome,
          }))}
          className="basic-multi-select"
          value={selectedStates}
          onChange={(e) => this.handleStateChange(e)}
          classNamePrefix="select"
        />
        {/* {!selectedStates.length && (
          <small id="selectedStatesHelp" className="form-text text-muted">
            {this.translate('Este campo é obrigatório')}
          </small>
        )} */}
      </div>
    );
  }

  renderCitySelection() {
    const { clientData } = this.context;
    const { stateList, cityList, formData } = this.state;
    const { selectedCities } = formData;

    if (formData.scope !== 'regional') return <></>;
    if (!stateList || !stateList.length) return <></>;

    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="selectedCities">
          Cidades que serão impactadas: <code>*</code>
        </label>
        <Select
          isMulti
          name="selectedCities"
          placeholder="Selecione quais cidades deseja atingir ..."
          options={_.map(cityList, (city) => ({
            value: city.city,
            label: city.city,
            state: city.state,
          }))}
          // options={}
          className="basic-multi-select"
          defaultValue={selectedCities}
          onChange={(e) => this.handleCityChange(e)}
          classNamePrefix="select"
        />
        {/* {!selectedCities.length && (
          <small id="selectedCitiesHelp" className="form-text text-muted">
            {this.translate('Este campo é obrigatório')}
          </small>
        )} */}
      </div>
    );
  }

  renderScope() {
    const { formData } = this.state;
    const { clientData } = this.context;

    return (
      <div className="form-group col-md-6">
        <label className="profile-label" htmlFor="scope">
          {this.translate('comprehensiveness')} <code>*</code>
        </label>
        <select
          className="form-control profile-placeholder"
          name="scope"
          required={clientData.segmentationChoice === 'true'}
          onChange={(e) => this.handleScopeChange(e)}
          value={
            clientData.segmentationChoice === 'false'
              ? 'national'
              : formData.scope
          }
        >
          <option value="">{this.translate('select')}...</option>
          <option value="national">{this.translate('national')}</option>
          {clientData.regional && clientData.regional.length && (
            <option value="regional">Segmentada</option>
          )}
        </select>
      </div>
    );
  }

  renderScopeForm() {
    const { scopeFieldName, regional } = this.context.clientData;
    const { scope, scopeValues } = this.state.formData;
    return (
      scope === 'regional' && (
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="scopeValue">
            {scopeFieldName} <code>*</code>
          </label>
          <Select
            isMulti
            required
            name="scopeValue"
            placeholder={`Selecione quais ${scopeFieldName} deseja atingir ...`}
            options={_.map(regional, (regional) => {
              return {
                value: normalizeLowerCase(regional),
                label: regional,
              };
            })}
            className="basic-multi-select"
            defaultValue={this.state.formData.scopeValues}
            onChange={(e) => this.handleFormDataChange('scopeValues', e)}
            classNamePrefix="select"
          />
          {scope === 'regional' && !scopeValues.length && (
            <small id="companyNameHelp" className="form-text text-muted">
              {this.translate('Este campo é obrigatório')}
            </small>
          )}
        </div>
      )
    );
  }

  openPreviewPage() {
    const { formData } = this.state;
    localStorage.setItem('actionPreview', JSON.stringify(formData));
    const win = window.open('/#/client/actionPreview', '_blank');
    win.focus();
  }

  handleUserImage(e, ref, fileIndex) {
    const { name, size } = e.target.files[0];
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];
    this.setState({ isUploading: true });
    if (size < 1000000) {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          fileIndex: randomizeName,
        },
      }));

      // this.handleFormDataChangeImageLogin(name, value);
      this.handleUploadSuccessImage(
        e.target.files[0],
        randomizeName,
        fileIndex,
        ref,
      );
    } else {
      this.setState({ isUploading: false });
      return alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
    }
  }
  handleUserPdf(e, ref, fileIndex) {
    const { name, size } = e.target.files[0];
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];
    this.setState({ isUploading: true });
    if (size < 1000000) {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          fileIndexPDF: randomizeName,
        },
      }));

      // this.handleFormDataChangeImageLogin(name, value);
      this.handleUploadSuccessPDF(
        e.target.files[0],
        randomizeName,
        fileIndex,
        ref,
      );
    } else {
      this.setState({ isUploading: false });
      return alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
    }
  }

  handleUploadSuccessImage(file, filename, fileIndex, ref) {
    const { formData } = this.state;
    const { files } = formData;
    firebase
      .storage()
      .ref(ref)
      .child(filename)
      .put(file)
      .then(() => {
        firebase
          .storage()
          .ref(ref)
          .child(filename)
          .getDownloadURL()
          .then((url) => {
            const newFiles = {
              ...files,
              [fileIndex]: url,
            };
            this.handleFormDataChange('files', newFiles);
            this.setState({ isUploading: false });
          });
      });
  }

  handleUploadSuccessPDF(file, filename, fileIndex, ref) {
    const { formData } = this.state;
    const { files } = formData;
    firebase
      .storage()
      .ref(ref)
      .child(filename)
      .put(file)
      .then(() => {
        firebase
          .storage()
          .ref(ref)
          .child(filename)
          .getDownloadURL()
          .then((url) => {
            const newFiles = {
              [fileIndex]: url,
            };
            this.handleFormDataChange('filePDF', newFiles);
            this.setState({ isUploading: false });
          });
      });
  }

  // handleUploadSuccess(filename, fileIndex) {
  //   const { formData } = this.state;
  //   const { files } = formData;
  //   // NotifyUpdate("Foto salva com sucesso! Clique em atualizar dados", "success", this.nId, true);
  //   this.setState({ avatar: filename, progress: 100, isUploading: false });
  //   firebase
  //     .storage()
  //     .ref('Actions/')
  //     .child(filename)
  //     .getDownloadURL()
  //     .then(url => {
  //       const newFiles = {
  //         ...files,
  //         [fileIndex]: url,
  //       };
  //       this.handleFormDataChange('files', newFiles);
  //     });
  // }

  // handleProgress(progress) {
  //   this.setState({ progress });
  // }

  // handleUploadError(error) {
  //   // NotifyUpdate("Tivemos um problema ao salvar sua foto, tente novamente ...", "error", this.nId, true);
  //   this.setState({ isUploading: false });
  //   console.log('upload handleUploadError:', error);
  // }

  // handleUploadStart() {
  //   // this.nId = Notify("Carregando foto aguarde ...", "loading", false);
  //   this.setState({ isUploading: true, progress: 0 });
  // }

  renderUncompletedText = () => {
    return (
      <span className="uncompleted-form">
        Existem campos vazios nesta seção
      </span>
    );
  };

  veriffyIfQuestionIsIncomplete = (question) => {
    return (
      !question.answerA ||
      !question.answerB ||
      !question.answerC ||
      !question.answerD ||
      !question.ponctuation ||
      !question.question ||
      !question.rightAnswer
    );
  };

  // handleQuestionChange(e, index) {
  // 	const { name, value } = e.target;
  // 	this.changeQuestionState(index, name, value);
  // }

  handleQuestionsChange(e, index) {
    const { name, value } = e.target;
    this.changeQuestionsState(index, name, value);
  }

  changeQuestionsState = (index, name, value) => {
    this.setState(
      (prevState) => ({
        questions: {
          ...prevState.questions,
          [index]: {
            ...prevState.questions[index],
            [name]: value,
          },
        },
      }),
      () => {
        this.phase2IsUncompleted(this.state.questions);
      },
    );
  };

  renderQuestion = (item, index) => {
    const { formErrors, questions } = this.state;

    questions[index] = questions[index] ? questions[index] : {};
    formErrors[index] = formErrors[index] ? formErrors[index] : {};

    return (
      <form>
        <div className="col-md-9">
          <label htmlFor="levelName" className="profile-label" />
          <h4
            className="arrow-toggle clickable collapsed m-top-0"
            data-toggle="collapse"
            data-target={`#question${index}`}
            aria-expanded="false"
          >
            Questão {Number(index) + 1}
            {this.veriffyIfQuestionIsIncomplete(questions[index]) && (
              <span className="uncompleted-form">
                Existem campos vazios nesta seção
              </span>
            )}
          </h4>
          <div
            id={`question${index}`}
            aria-expanded="false"
            className="collapse"
          >
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="question">
                {this.translate('Pergunta')} <code>*</code>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="question"
                placeholder={this.translate('Escreva sua pergunta aqui...')}
                onChange={(e) => this.handleQuestionsChange(e, index)}
                onBlur={(e) => this.phase2IsUncompleted()}
                value={questions[index].question}
              />
            </div>

            {this.renderOption(index, 'A')}
            {this.renderOption(index, 'B')}
            {/* {this.renderOption(index, 'C')}
            {this.renderOption(index, 'D')} */}

            <label className="profile-label col-md-12" htmlFor="question">
              {this.translate('Alternativa certa')} <code>*</code>
            </label>

            <div className="col-md-12 d-flex p-0">
              {this.renderRightAnswerRadio(index, 'A')}
              {this.renderRightAnswerRadio(index, 'B')}
              {/* {this.renderRightAnswerRadio(index, 'C')}
              {this.renderRightAnswerRadio(index, 'D')} */}
            </div>

            {/* <div className="form-group col-md-4">
              <label className="profile-label" htmlFor="ponctuation">
                {this.translate('Pontuação')} <code>*</code>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="ponctuation"
                type="number"
                placeholder={this.translate('Pontuação')}
                onChange={(e) => {
                  this.handleQuestionsChange(e, index);
                }}
                value={questions[index].ponctuation}
              />
            </div> */}
          </div>
        </div>
      </form>
    );
  };

  renderRightAnswerRadio(questionIndex, answer) {
    const { questions } = this.state;
    return (
      <div className="custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          id={answer}
          value={answer}
          name="rightAnswer"
          className="custom-control-input"
          onChange={(e) => this.handleQuestionsChange(e, questionIndex)}
          onBlur={(e) => this.phase2IsUncompleted()}
          checked={questions[questionIndex].rightAnswer === answer}
        />
        <label className="custom-control-label">{answer}</label>
      </div>
    );
  }

  renderQuestionForm = () => {
    const { formData } = this.state;

    formData.amountOfQuestions = formData.amountOfQuestions
      ? Number(formData.amountOfQuestions)
      : 1;

    const auxArray = {};
    for (let i = 0; i < formData.amountOfQuestions; i++) {
      auxArray[i] = i;
    }

    return (
      <div>
        <div className="row">
          <div className="form-group col-md-4 d-flex align-items-center m-0">
            <label className="profile-label m-0" htmlFor="state">
              Quantidade de questões:
            </label>
            <select
              className="form-control profile-placeholder m-10 w-25"
              onChange={(e) => this.handleUserInput(e)}
              onBlur={(e) => this.phase2IsUncompleted()}
              id="amountOfQuestions"
              name="amountOfQuestions"
              value={formData.amountOfQuestions}
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
              <option value={11}>11</option>
              <option value={12}>12</option>
            </select>
          </div>
        </div>

        {_.map(auxArray, (item, index) => this.renderQuestion(item, index))}
      </div>
    );
  };

  renderOption(questionIndex, option) {
    const { questions } = this.state;
    return (
      <div className="form-group col-md-10 d-flex">
        <div className="answer-square">
          <p className="answer-letter">{option}</p>
        </div>
        <input
          required
          className="form-control profile-placeholder"
          name={`answer${option}`}
          placeholder={this.translate('Escreva a alternativa aqui...')}
          onChange={(e) => this.handleQuestionsChange(e, questionIndex)}
          value={questions[questionIndex][`answer${option}`]}
          onBlur={(e) => this.phase2IsUncompleted()}
        />
      </div>
    );
  }

  handleProgress(progress) {
    this.setState({ progress });
  }
  handleUploadError(error) {
    // NotifyUpdate("Tivemos um problema ao salvar sua foto, tente novamente ...", "error", this.nId, true);
    this.setState({ isUploading: false });
    console.log('upload handleUploadError:', error);
  }
  handleUploadStart() {
    // this.nId = Notify("Carregando foto aguarde ...", "loading", false);
    this.setState({ isUploading: true, progress: 0 });
  }

  renderPhase1() {
    const {
      formData,
      phase1Uncompleted,
      formButtonPressed,
      seasons,
    } = this.state;
    const { clientData } = this.context;

    const seasonSelected =
      formData.season &&
      formData.season.id &&
      seasons &&
      seasons.length > 0 &&
      seasons.find((season) => season.id === formData.season.id);

    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase1"
          aria-expanded="false"
        >
          Informações básicas
          {phase1Uncompleted &&
            formButtonPressed &&
            this.renderUncompletedText()}
        </h4>
        <div id="phase1" aria-expanded="false" className="collapse">
          <div className="col-md-7">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="actionTitle">
                {this.translate('Título da publicação')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}>
                  (Até 72 caracteres)
                </span>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="call-action"
                />
                <ReactTooltip
                  id="call-action"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline={true}
                >
                  <span>
                    Texto que seu público-alvo vê junto com o link compartilhado
                    pelo usuário
                  </span>
                </ReactTooltip>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="actionTitle"
                placeholder={this.translate('Título da publicação')}
                onChange={(e) => this.handleUserInput(e)}
                value={formData.actionTitle}
              />
            </div>

            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="callToAction">
                {this.translate(
                  'Call to action para participação da publicação',
                )}{' '}
                &nbsp;
                <span style={{ fontSize: '10px', display: 'contents' }}>
                  (Até 38 caracteres)
                </span>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="callToAction"
                />
                <ReactTooltip
                  id="callToAction"
                  type="dark"
                  effect="solid"
                  multiline
                  className="tool-tip"
                >
                  <span>
                    {this.translate('call to action ex Compartilhar agora')}
                  </span>
                </ReactTooltip>
              </label>
              <input
                required={false}
                type="text"
                className="form-control profile-placeholder"
                name="callToAction"
                onChange={(e) => this.handleUserInput(e)}
                value={this.state.formData.callToAction}
                placeholder="Ex: 'Participar', 'Jogar Agora'... "
              />
            </div>

            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="videoURL">
                {this.translate('Link do Vídeo')}&nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="videoLink"
                />
                <ReactTooltip
                  id="videoLink"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>{this.translate('video-link-text1')}</span>
                </ReactTooltip>
              </label>
              <input
                required={false}
                type="url"
                className="form-control profile-placeholder"
                name="videoURL"
                placeholder={this.translate('Link do Vídeo')}
                onChange={(e) => this.handleUserInput(e)}
                value={formData.videoURL}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="callActionText">
                {this.translate('Access Content')}&nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="call-action2"
                />
                <ReactTooltip
                  id="call-action2"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>{this.translate('call-action-quiz-i')}</span>
                </ReactTooltip>
              </label>
              <input
                required={false}
                maxLength={38}
                className="form-control profile-placeholder"
                name="callActionText"
                placeholder={this.translate('access')}
                onChange={(e) => this.handleUserInput(e)}
                value={formData.callActionText}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="contentURL">
                {this.translate('Link do Conteúdo')}&nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="contentLink"
                />
                <ReactTooltip
                  id="contentLink"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>{this.translate('content-link-text')}</span>
                </ReactTooltip>
              </label>
              <input
                required={false}
                type="url"
                className="form-control profile-placeholder"
                name="contentURL"
                placeholder={this.translate('Link do Conteúdo')}
                onChange={(e) => this.handleUserInput(e)}
                value={formData.contentURL}
              />
            </div>
          </div>

          <div className="col-md-12 row p-0-right">
            <div className="daterow">
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="startDate">
                  {this.translate('start-date')} <code>*</code>
                </label>
                <input
                  disabled={_.isEmpty(formData.season)}
                  required
                  type="date"
                  className="form-control profile-placeholder"
                  name="startDate"
                  placeholder={this.translate('start-date')}
                  onChange={(e) => this.handleDateChange(e)}
                  onBlur={() => this.handleDateChangeValid()}
                  value={formData.startDate}
                />

                <span
                  style={{ color: 'red' }}
                  className="help-block hidden"
                  id="handleDateChangeValid-1"
                >
                  {this.state.msg1}
                </span>
              </div>
              <div className="form-group col-md-2">
                <label className="profile-label" htmlFor="startDateTime">
                  {this.translate('start-time')} <code>*</code>
                </label>
                <input
                  disabled={_.isEmpty(formData.season)}
                  required
                  type="time"
                  className="form-control profile-placeholder"
                  name="startDateTime"
                  placeholder={this.translate('start-time')}
                  onChange={(e) => this.handleUserInput(e)}
                  onBlur={() => this.handleDateChangeValid()}
                  value={formData.startDateTime}
                />
              </div>

              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="endDate">
                  {this.translate('end-date')} <code>*</code>
                </label>
                <input
                  disabled={_.isEmpty(formData.season)}
                  required
                  type="date"
                  className="form-control profile-placeholder"
                  name="endDate"
                  placeholder={this.translate('end-date')}
                  onChange={(e) => this.handleDateChange(e)}
                  onBlur={() => this.handleDateChangeValid()}
                  value={formData.endDate}
                />
                <span
                  style={{ color: 'red' }}
                  className="help-block hidden"
                  id="handleDateChangeValid-3"
                >
                  {this.state.msg3}
                </span>
              </div>

              <div className="form-group col-md-2 ">
                <label className="profile-label" htmlFor="endDateTime">
                  {this.translate('end-time')} <code>*</code>
                </label>
                <input
                  disabled={_.isEmpty(formData.season)}
                  required
                  type="time"
                  className="form-control profile-placeholder"
                  name="endDateTime"
                  placeholder={this.translate('end-time')}
                  onChange={(e) => this.handleUserInput(e)}
                  onBlur={() => this.handleDateChangeValid()}
                  value={formData.endDateTime}
                />
                {this.state.errorTime1 && (
                  <span className="help-block" id="handleTimeChangeValid-1">
                    {this.state.errorTimeMsg1}
                  </span>
                )}
              </div>
              <div className="form-group col-md-6 ">
                <label className="profile-label" htmlFor="fileArquive">
                  Material de apoio &nbsp;
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="fileArquive"
                  />
                  <ReactTooltip
                    id="fileArquive"
                    type="dark"
                    effect="solid"
                    className="tool-tip"
                    multiline
                  >
                    <span>Material de apoio para os usuarios &nbsp;</span>
                  </ReactTooltip>
                </label>
                <input
                  required={false}
                  type="text"
                  className="form-control profile-placeholder"
                  name="nameFileArquive"
                  placeholder="Ex: Material de Apoio"
                  onChange={(e) => this.handleUserInput(e)}
                  value={formData.nameFileArquive}
                />
              </div>
              <div className="form-group col-md-6">
                <label className="profile-label" htmlFor="fileArquiveQuiz">
                  {formData.nameFileArquive
                    ? formData.nameFileArquive
                    : `Arquivo Material de apoio`}
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="fileArquiveQuiz"
                  />
                  <ReactTooltip
                    id="fileArquiveQuiz"
                    type="dark"
                    effect="solid"
                    className="tool-tip"
                    multiline
                  >
                    <span>{this.translate('technical-fix-text1')} &nbsp;</span>
                  </ReactTooltip>
                </label>
                <input
                  type="file"
                  accept="pdf/*"
                  id="profilePic"
                  onChange={(event) => {
                    this.handleUserPdf(event, `Actions/`, 'fileArquiveQuiz');
                  }}
                />
                {/* <FileUploader
              name="fileArquiveQuiz"
              id="fileArquiveQuiz"
              randomizeFilename
              className="form-control profile-placeholder"
              disabled={this.state.isUploading}
              required={false}
              storageRef={firebase.storage().ref('Actions/')}
              onUploadStart={() => this.handleUploadStart()}
              onUploadError={error => this.handleUploadError(error)}
              onUploadSuccess={filename =>
                this.handleUploadSuccess(filename, 'fileArquiveQuiz')
              }
              onProgress={progress => this.handleProgress(progress)}
            /> */}
              </div>
              <div className="form-group points-by-share col-md-12 mt-5">
                <label className="profile-label" htmlFor="countDownVisible">
                  {this.translate(
                    'Exibir cronômetro regressivo para o término da publicação?',
                  )}
                  &nbsp;
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="countDownVisible"
                  />
                </label>
                <ReactTooltip
                  id="countDownVisible"
                  type="dark"
                  effect="solid"
                  multiline
                >
                  <span>{this.translate('countDownVisible-info')}</span>
                </ReactTooltip>
                <div className="form-group col-md-3">
                  <div className="profile-placeholder d-flex justify-content-between">
                    <label className="checkbox-inline">
                      <input
                        type="radio"
                        value
                        name="countDownVisible"
                        onChange={(e) => this.handleUserInput(e)}
                        checked={
                          this.state.formData.countDownVisible !== 'false'
                        }
                      />
                      &nbsp;{this.translate('yes')}
                    </label>
                    <label className="checkbox-inline">
                      <input
                        type="radio"
                        value={false}
                        name="countDownVisible"
                        onChange={(e) => this.handleUserInput(e)}
                        checked={
                          this.state.formData.countDownVisible === 'false'
                        }
                      />
                      &nbsp;{this.translate('no')}
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group points-by-share col-md-12">
                <label className="profile-label" htmlFor="shareButtonsVisible">
                  {this.translate(
                    'Exibir botões de compartilhamento com terceiros?',
                  )}
                  &nbsp;
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="shareButtonsVisible"
                  />
                </label>
                <ReactTooltip
                  id="shareButtonsVisible"
                  type="dark"
                  effect="solid"
                  multiline
                >
                  <span>{this.translate('shareButtonsVisible-info')}</span>
                </ReactTooltip>
                <div className="form-group col-md-3">
                  <div className="profile-placeholder d-flex justify-content-between">
                    <label className="checkbox-inline">
                      <input
                        type="radio"
                        value
                        name="shareButtonsVisible"
                        onChange={(e) => this.handleUserInput(e)}
                        checked={
                          this.state.formData.shareButtonsVisible !== 'false'
                        }
                      />
                      &nbsp;{this.translate('yes')}
                    </label>
                    <label className="checkbox-inline">
                      <input
                        type="radio"
                        value={false}
                        name="shareButtonsVisible"
                        onChange={(e) => this.handleUserInput(e)}
                        checked={
                          this.state.formData.shareButtonsVisible === 'false'
                        }
                      />
                      &nbsp;{this.translate('no')}
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group points-by-share col-md-12">
                <label className="profile-label" htmlFor="startDateVisible">
                  {this.translate(
                    'Data de início visível para o usuário no card da ação?',
                  )}
                  &nbsp;
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="startDateVisible"
                  />
                </label>
                <ReactTooltip
                  id="startDateVisible"
                  type="dark"
                  effect="solid"
                  multiline
                >
                  <span>{this.translate('startDateVisible-info')}</span>
                </ReactTooltip>
                <div className="form-group col-md-3">
                  <div className="profile-placeholder d-flex justify-content-between">
                    <label className="checkbox-inline">
                      <input
                        type="radio"
                        value
                        name="startDateVisible"
                        onChange={(e) => this.handleUserInput(e)}
                        checked={
                          this.state.formData.startDateVisible !== 'false'
                        }
                      />
                      &nbsp;{this.translate('yes')}
                    </label>
                    <label className="checkbox-inline">
                      <input
                        type="radio"
                        value={false}
                        name="startDateVisible"
                        onChange={(e) => this.handleUserInput(e)}
                        checked={
                          this.state.formData.startDateVisible === 'false'
                        }
                      />
                      &nbsp;{this.translate('no')}
                    </label>
                  </div>
                </div>
              </div>
              {/* AQUI ESTA A OPÇÃO PARA SABER SE O USUARIO PODE RESPONDER O QUIZ SOMENTE UMA VEZ OU NAO */}
              <div className="form-group points-by-share col-md-12">
                <label className="profile-label" htmlFor="quizChoice">
                  {this.translate(
                    'O quiz poderá ser respondido apenas uma vez?',
                  )}
                  &nbsp;
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="quizChoice"
                  />
                </label>
                <ReactTooltip
                  id="quizChoice"
                  type="dark"
                  effect="solid"
                  multiline
                >
                  <span>{this.translate('shareButtonsVisible-info')}</span>
                </ReactTooltip>
                <div className="form-group col-md-3">
                  <div className="profile-placeholder d-flex justify-content-between">
                    <label className="checkbox-inline">
                      <input
                        type="radio"
                        value
                        name="quizChoice"
                        onChange={(e) => this.handleUserInput(e)}
                        checked={this.state.formData.quizChoice !== 'false'}
                      />
                      &nbsp;{this.translate('yes')}
                    </label>
                    <label className="checkbox-inline">
                      <input
                        type="radio"
                        value={false}
                        name="quizChoice"
                        onChange={(e) => this.handleUserInput(e)}
                        checked={this.state.formData.quizChoice === 'false'}
                      />
                      &nbsp;{this.translate('no')}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="form-group col-md-6">
						<label className="profile-label" htmlFor="scope">
							{this.translate("comprehensiveness")} <code>*</code>
						</label>
						<select
							className="form-control profile-placeholder"
							name="scope"
							required
							onChange={e => this.handleScopeChange(e)}
							value={formData.scope}>
							<option value="">{this.translate("select")}...</option>
							<option value="national">{this.translate("national")}</option>
							<option value="state">{this.translate("Estadual")}</option>
							<option value="regional">
								{clientData && clientData.scopeFieldName
									? clientData.scopeFieldName
									: this.translate("Unidade")}
							</option>
						</select>
					</div> */}
        </div>
        <hr />
      </div>
    );
  }

  renderPhase2() {
    // const { phase2Uncompleted, formButtonPressed } = this.state;

    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase2"
          aria-expanded="false"
        >
          Questões
          {/* {phase2Uncompleted && formButtonPressed && this.renderUncompletedText()} */}
        </h4>
        <div id="phase2" aria-expanded="false" className="collapse">
          <div className="col-md-12 row p-0-right">
            {this.renderQuestionForm()}
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderForm() {
    return (
      <div className="container-fluid">
        {/* {this.renderPhase1()} */}
        {this.renderPhase2()}
        <div className="row col-md-12 justify-content-center d-flex">
          <div className="col-md-6">
            <button
              type="submit"
              // disabled={
              //   this.state.creatingAction ||
              //   this.state.isUploading ||
              //   this.state.errorForm1 ||
              //   this.state.errorTime1 ||
              //   this.state.questionFormIsComplete
              // }
              onClick={() => {
                this.setState({
                  formButtonPressed: true,
                  phase1Uncompleted: this.phase1IsUncompleted(),
                  phase2Uncompleted: this.phase2IsUncompleted(),
                });
              }}
              className="btn btn-oq btn-oq-lg btn-block"
              style={{ marginBottom: '36px' }}
            >
              {this.translate('Salvar publicação')}
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderImagePreview(img) {
    return (
      <img
        src={img}
        max-height="220px"
        className="img-thumbnail img-responsive form-group actim"
        alt="action banner"
      />
    );
  }

  // renderScopeForm() {
  // 	const { scope } = this.state.formData;
  // 	const {
  // 		stateList,
  // 		regionalList,
  // 		loadingRegionalList,
  // 		loadingStateList,
  // 		regionalIsEmpty,
  // 	} = this.state;
  // 	let html;
  // 	switch (scope) {
  // 		case "state":
  // 			html = (
  // 				<div className="form-group col-md-3">
  // 					<label className="profile-label" htmlFor="scopeValue">
  // 						{this.translate("state")} <code>*</code>
  // 					</label>
  // 					<select
  // 						className="form-control profile-placeholder"
  // 						name="scopeValue"
  // 						required
  // 						disabled={loadingStateList}
  // 						onChange={e => this.handleUserInput(e)}
  // 						value={this.state.formData.scopeValue}>
  // 						<option value="">
  // 							{loadingStateList
  // 								? this.translate("Carregando estados") + "..."
  // 								: this.translate("Selecione um estado") + "..."}
  // 						</option>
  // 						{stateList &&
  // 							_.map(stateList, state => {
  // 								return <option value={state.id}>{state.estado}</option>;
  // 							})}
  // 					</select>
  // 				</div>
  // 			);
  // 			break;
  // 		case "regional":
  // 			html = (
  // 				<div className="form-group col-md-9">
  // 					<label className="profile-label" htmlFor="scopeValue">
  // 						{this.translate("Regional")} <code>*</code>
  // 					</label>
  // 					<Select
  // 						isMulti
  // 						name="scopeValue"
  // 						placeholder={this.translate("Selecione uma regional...")}
  // 						isDisabled={loadingRegionalList}
  // 						options={_.map(regionalList, regional => {
  // 							return { value: this.normalizeString(regional), label: regional };
  // 						})}
  // 						isLoading={loadingRegionalList}
  // 						className="basic-multi-select"
  // 						defaultValue={this.state.formData.scopeValues}
  // 						onChange={e => this.handleFormDataChange("scopeValues", e)}
  // 						classNamePrefix="select"
  // 					/>
  // 					{regionalIsEmpty && (
  // 						<small id="companyNameHelp" className="form-text text-muted">
  // 							{this.translate("Este campo é obrigatório")}
  // 						</small>
  // 					)}
  // 				</div>
  // 			);
  // 			break;
  // 		default:
  // 			break;
  // 	}

  // 	return html;
  // }

  phase1IsUncompleted() {
    const { formData } = this.state;

    return (
      !formData.actionTitle ||
      !formData.files ||
      !formData.files.image1 ||
      !formData.startDate ||
      !formData.startDateTime ||
      !formData.startDateTime ||
      !formData.endDate ||
      !formData.season
    );
  }

  phase2IsUncompleted = async (questions) => {
    // const { questions } = this.state;
    const { amountOfQuestions } = this.state.formData;

    if (_.size(questions) > amountOfQuestions) {
      // let diff = _.size(questions) - amountOfQuestions;
      // ajustedQuestions = Object.keys(questions).slice(amountOfQuestions, _.size(questions))
      // let adjustedQuestions = questions.slice(0, amountOfQuestions)
      // for (let i=diff; i<_.size(questions); i++) {
      // 	delete questions[i];
      // }
      const adjustedQuestions = [];
      _.each(questions, (question, key) => {
        if (key < amountOfQuestions) adjustedQuestions.push(question);
      });
      // this.handleFormDataChange('questions', this.state.questions)
      await this.setState({ questions: adjustedQuestions });
    }

    let questionFormIsComplete = true;
    _.each(this.state.questions, (question) => {
      if (this.veriffyIfQuestionIsIncomplete(question)) {
        questionFormIsComplete = false;
      }
    });

    this.setState({ questionFormIsComplete: !questionFormIsComplete });

    return !questionFormIsComplete;
  };

  validateLengthCallToAction(callToAction, callActionText) {
    if (callToAction && callToAction.length > 38) {
      this.setState({ creatingAction: false }, () => {
        toast.error(
          'Call to action para participação da publicação: Limite de 38 caracteres.',
          {
            type: toast.TYPE.ERROR,
            autoClose: true,
          },
        );
      });
      return false;
    }

    if (callActionText && callActionText.length > 38) {
      this.setState({ creatingAction: false }, () => {
        toast.error(
          'Call to Action para Acessar Conteúdo : Limite de 38 caracteres.',
          {
            type: toast.TYPE.ERROR,
            autoClose: true,
          },
        );
      });
      return false;
    }

    return true;
  }

  warningLengthTitleAndSubtitle(actionTitle) {
    if (actionTitle && actionTitle.length > 72) {
      toast.warn(
        'Título da publicação: Você ultrapassou o limite de 72 caracteres.',
        {
          type: toast.TYPE.WARNING,
          autoClose: true,
        },
      );
    }
  }

  handleFormSubmit(e) {
    const { formData } = this.state;
    e.preventDefault();

    if (
      !this.validateLengthCallToAction(
        formData.callToAction,
        formData.callActionText,
      )
    ) {
      return;
    }

    this.warningLengthTitleAndSubtitle(formData.actionTitle);

    this.createAction();
  }

  validateScope() {
    const { scope, scopeValues } = this.state.formData;
    if (scope === 'regional' && (!scopeValues || !scopeValues.length)) {
      this.handleFormDataChange('scopeValue', null);
      this.setState({ regionalIsEmpty: true });
      return false;
    }

    return true;
  }

  handleModalToggle(modal) {
    this.setState((prevState) => ({
      [modal]: !prevState[modal],
    }));
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back menu-link oq-margin-bottom"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fas fa-arrow-left" aria-hidden="true" /> &nbsp;
          <span className="sidebarText">Voltar</span>
        </button>
      </div>
    );
  }

  render() {
    const { formData, awardsModalIsOpen, extraPointsModalIsOpen } = this.state;
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />

          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              <div className="col-md-12">{this.renderBackButton()}</div>
              <h1 className="oq-filter-title">
                <i className="fa fa-rocket" />
                <span style={{ fontWeight: 'bold' }}>&nbsp; Perguntas</span>
                <code>*</code>
                <span className="filds-mandatory">Campos obrigatórios</span>
              </h1>
            </div>
            <form onSubmit={(e) => this.handleFormSubmit(e)}>
              {this.renderForm()}
            </form>
          </div>
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />
      //     <div className="container-fluid">
      //       <div className="col-md-12">{this.renderBackButton()}</div>
      //       <h1 className="oq-filter-title">
      //         <i className="fa fa-rocket" />
      //         <span style={{ fontWeight: 'bold' }}>
      //           &nbsp; {this.translate('Novo quiz')}
      //         </span>
      //         <code>*</code>
      //         <span className="filds-mandatory">Campos obrigatórios</span>
      //       </h1>
      //     </div>
      //     <form onSubmit={e => this.handleFormSubmit(e)}>
      //       {this.renderForm()}
      //     </form>
      //   </div>
      //   <ActionAwards
      //     modalIsOpen={awardsModalIsOpen}
      //     awardsList={formData.awardsList}
      //     updateAwardsList={awardsList =>
      //       this.handleFormDataChange('awardsList', awardsList)
      //     }
      //     closeModal={() => this.handleModalToggle('awardsModalIsOpen')}
      //   />
      //   <ExtraPoints
      //     modalIsOpen={extraPointsModalIsOpen}
      //     extraPointsList={formData.extraPointsList}
      //     updateAwardsList={extraPointsList =>
      //       this.handleFormDataChange('extraPointsList', extraPointsList)
      //     }
      //     closeModal={() => this.handleModalToggle('extraPointsModalIsOpen')}
      //   />
      // </div>
    );
  }
}

Dashboard.contextTypes = {
  authUser: PropTypes.object,
  clientData: PropTypes.object,
};

const authCondition = (authUser) => !!authUser;
export default withAuthorization(authCondition)(Dashboard);
