import React from 'react';
import * as GoogleIcons from 'react-icons/md';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import * as routes from '../../../routes';
// import { auth } from '../../../firebase';

const SignOutAdminButton = () => {
  return (
    <button className="btn btn-default" type="button">
      Sair
    </button>
  );
};

const SignOutClientLink = (props) => <SignOutClientComponent {...props} />;
const SignOutClientComponent = (props) => (
  <Link id="oq-logout" to={routes.SIGIN_CLIENT} onClick={() => {}}>
    <i>
      <GoogleIcons.MdExitToApp />
    </i>
    <span>Sair</span>
  </Link>
);

const SignOutClientPage = withRouter(({ history }) => {
  return (
    <div className="container">
      <h2 className="text-center">Saindo ...</h2>
    </div>
  );
});

export default SignOutAdminButton;

export { SignOutAdminButton, SignOutClientLink, SignOutClientPage };
