import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import * as routes from "../../../routes";
import { NavBar } from "../../components/NavBar";
import { SideBar } from "../../components/SideBar";
import withAuthorization from "../../utils/Session/withAuthorization";
// import { auth } from '../../../firebase';
import { faTachometerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileSaver from "file-saver";
import i18next from "i18next";
import XLSX from "xlsx";
import { Notify } from "../../../components/Notify";
import api from "../../../services/api";

class GetCodigoConduta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authUser: {},
      users: [],
      params: {},
      loading: false,
    };
  }
  async findDriver() {
    const data = this.state.params;

    if (data.dateInit || data.dateFinish) {
      if (data.dateInit && !data.dateFinish) {
        return Notify("Selecione a data final", "error");
      }
      if (data.dateFinish && !data.dateInit) {
        return Notify("Selecione a data inicial", "error");
      }
    }
    const params = {
      data,
    };
    this.setState({ loading: true });
    const res = await api.post("/auth/findCodigoConduta", params);
    let result = res.data.user;
    this.setState({ users: result });

    this.setState({ loading: false });
  }
  s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }
  displayBase64Image(base64Image) {
    // Converte a imagem base64 em um Blob
    const byteCharacters = atob(base64Image);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/png" });

    // Cria uma URL de objeto Blob
    const blobUrl = URL.createObjectURL(blob);

    return blobUrl;
  }
  async exporDrivers() {
    this.setState({ isLoadingLeadsForExport: true }, async () => {
      try {
        this.setState({
          isLoadingLeadsForExport: false,
        });

        const leads = this.state.users;

        const defaultArray = [];
        const defaultObject = {
          Nome: "--",
          Telefone: "--",
          CPF: "--",
          Estado: "--",
          Cidade: "--",
          // Placa: "--",
          Empresa: "--",
          "Data de resposta": "--",
          "Porcentagem de acertos": "--",
        };

        leads.forEach((lead) => {
          console.log("🚀 ~ GetCodigoConduta ~ leads.forEach ~ lead:", lead);
          const leadObj = { ...defaultObject };
          let percent =
            (lead.questionCorretly.length * 100) /
            (lead.questionCorretly.length + lead.questionNotCorretly.length);
          console.log(this.state.users);

          if (lead.name) leadObj["Nome"] = lead.name;
          if (lead.cpf) leadObj["CPF"] = lead.cpf;
          if (lead.phone) leadObj["Telefone"] = lead.phone;
          if (lead.city) leadObj["Cidade"] = lead.city;
          if (lead.state) leadObj["Estado"] = lead.state;
          if (lead.empresa) leadObj["Empresa"] = lead.empresa;
          if (lead.signature) leadObj["Assinatura"] = lead.signature;
          // if (lead.placa) leadObj["Placa"] = lead.placa;
          if (lead.questionCorretly)
            leadObj["Porcentagem de acertos"] = percent.toFixed(1);
          if (lead.createdAt)
            leadObj["Data de resposta"] = moment(lead.createdAt).format(
              "DD/MM/YYYY HH:mm:ss",
            );

          defaultArray.push(leadObj);
        });

        const ws = XLSX.utils.json_to_sheet(defaultArray);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "PublicoAlvo");

        const buf = XLSX.write(wb, {
          type: "buffer",
          bookType: "xlsx",
        });

        FileSaver.saveAs(
          new Blob([this.s2ab(buf)], {
            type: "application/octet-stream",
          }),
          "Relatorio-Codigo-Conduta.xlsx",
        );
      } catch (error) {
        this.setState({ isLoadingLeadsForExport: false });
        console.log("exporLeads error:", error);
        // this.getInitialState();
        return;
      }
    });

    return;
  }

  maskCpf(event) {
    let cpf = event.target.value.replace(/\D/g, "");

    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1-$2");
    event.target.value = cpf;
    this.setState((prevState) => ({
      ...prevState,
      params: {
        ...prevState.params,
        cpf: event,
      },
    }));
    return event;
  }
  handleInput(e) {
    const { name, value } = e.target;

    if (name === "cpf") {
      let cpf = e.target.value.replace(/\D/g, "");

      cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
      cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
      cpf = cpf.replace(/(\d{3})(\d)/, "$1-$2");
      e.target.value = cpf;
      this.setState((prevState) => ({
        ...prevState,
        params: {
          ...prevState.params,
          cpf,
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        params: {
          ...prevState.params,
          [name]: value,
        },
      }));
    }
    if (name === "dateInit" && !name === "dateFinish") {
      console.log("nao selecionou a data final");
    }
    console.log(this.state);
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />

          <div className="oq-content-work">
            <ToastContainer />
            <div
              className="container-fluid  text-center"
              style={{ marginLeft: "2%", marginRight: "2%", marginTop: "1%" }}
            >
              <br />
              <br />
              <div className="col-12 ">
                {/* <h4>Bem Vindo!</h4> */}
                <div className="d-flex text-center justify-content-center">
                  {/* <h4>Buscar motorista</h4> */}
                  <div className="mx-3 px-3">
                    <input
                      type="text"
                      name="name"
                      placeholder="Pesquisar por nome"
                      onChange={(e) => this.handleInput(e)}
                    />
                  </div>
                  <div className="mx-3 px-3">
                    <input
                      type="text"
                      name="cpf"
                      maxLength={14}
                      placeholder="Pesquisar por cpf"
                      onChange={(e) => this.handleInput(e)}
                      // onChange={(e) => this.maskCPF(e)}
                    />
                  </div>
                  {/* <div className="mx-3 px-3">
                    <input
                      type="text"
                      name="placa"
                      placeholder="Pesquisar por placa"
                      onChange={(e) => this.handleInput(e)}
                    />
                  </div> */}

                  <button onClick={() => this.findDriver()}>
                    {this.state.loading
                      ? "Carregando..."
                      : "Buscar formulários"}
                  </button>
                </div>
                <div
                  className="d-flex text-center justify-content-center m-5 p-3"
                  style={{ marginTop: "15px" }}
                >
                  <div className="mx-3 px-3">
                    <input
                      type="date"
                      name="dateInit"
                      placeholder="dd-mm-yyyy"
                      onChange={(e) => this.handleInput(e)}
                    />
                  </div>
                  <div className="mx-3 px-3">
                    <input
                      type="date"
                      name="dateFinish"
                      placeholder="dd-mm-yyyy"
                      onChange={(e) => this.handleInput(e)}
                    />
                  </div>
                  {this.state.params &&
                    this.state.params.dateInit &&
                    this.state.params.dateFinish &&
                    this.state.users.length > 0 && (
                      <div className="mx-3 px-3">
                        <button onClick={() => this.exporDrivers()}>
                          Exportar Relatório
                        </button>
                      </div>
                    )}
                </div>
                <div className="col-12">
                  {this.state.params &&
                    this.state.params.dateInit &&
                    this.state.users &&
                    this.state.users.length > 0 && (
                      <>
                        <br />
                        {this.state.users.map((user) => {
                          let percent =
                            (user.questionCorretly.length * 100) /
                            (user.questionCorretly.length +
                              user.questionNotCorretly.length);
                          return (
                            <>
                              <div className=" col-5 text-left m-auto">
                                <div className="p-3 ">
                                  <label htmlFor="">Nome: </label>
                                  <input
                                    type="text"
                                    disabled
                                    value={user.name}
                                    className="input-answer"
                                  />
                                </div>
                                {/* <div className="p-3 ">
                                  <label htmlFor="">Placa: </label>
                                  <input
                                    type="text"
                                    disabled
                                    value={user.placa}
                                    className="input-answer"
                                  />
                                </div>{" "} */}
                                <br />
                                <div className="p-3 ">
                                  <label htmlFor="">Data de resposta: </label>
                                  <input
                                    type="text"
                                    disabled
                                    value={moment(user.createdAt).format(
                                      "DD/MM/YYYY",
                                    )}
                                    className="input-answer"
                                  />
                                </div>{" "}
                                <br />
                                <div className={`p-3 `}>
                                  <label htmlFor="">
                                    Porcentagem de acertos:{" "}
                                  </label>
                                  <input
                                    type="text"
                                    disabled
                                    value={`${percent.toFixed(1)}%`}
                                    className={`input-answer ${
                                      percent >= 100
                                        ? "percent-corret"
                                        : "percent-not-correct"
                                    }`}
                                  />
                                </div>{" "}
                              </div>
                              <br />
                              <br />
                            </>
                          );
                        })}
                      </>
                    )}
                  {this.state.params &&
                    !this.state.params.dateInit &&
                    this.state.users &&
                    this.state.users.length > 0 && (
                      <>
                        <br />
                        {this.state.users.map((user) => {
                          let percent =
                            (user.questionCorretly.length * 100) /
                            (user.questionCorretly.length +
                              user.questionNotCorretly.length);
                          return (
                            <>
                              <div className=" col-5 text-left m-auto">
                                <div className="p-3 ">
                                  <label htmlFor="">Nome: </label>
                                  <input
                                    type="text"
                                    disabled
                                    value={user.name}
                                    className="input-answer"
                                  />
                                </div>
                                <br />
                                <div className="p-3 ">
                                  <label htmlFor="">CPF: </label>
                                  <input
                                    type="text"
                                    disabled
                                    value={user.cpf}
                                    className="input-answer"
                                  />
                                </div>{" "}
                                <br />
                                <div className="p-3 ">
                                  <label htmlFor="">Telefone: </label>
                                  <input
                                    type="text"
                                    disabled
                                    value={user.phone}
                                    className="input-answer"
                                  />
                                </div>{" "}
                                <br />
                                <div className="p-3 ">
                                  <label htmlFor="">Empresa: </label>
                                  <input
                                    type="text"
                                    disabled
                                    value={user.empresa}
                                    className="input-answer"
                                  />
                                </div>{" "}
                                <br />
                                <div className="p-3 ">
                                  <label htmlFor="">Cidade: </label>
                                  <input
                                    type="text"
                                    disabled
                                    value={user.city}
                                    className="input-answer"
                                  />
                                </div>{" "}
                                <br />
                                <div className="p-3 ">
                                  <label htmlFor="">Estado: </label>
                                  <input
                                    type="text"
                                    disabled
                                    value={user.state}
                                    className="input-answer"
                                  />
                                </div>{" "}
                                <br />
                                <div className="p-3 ">
                                  <label htmlFor="">Placa: </label>
                                  <input
                                    type="text"
                                    disabled
                                    value={user.placa}
                                    className="input-answer"
                                  />
                                </div>{" "}
                                <br />
                                <div className="p-3 ">
                                  <label htmlFor="">Data de resposta: </label>
                                  <input
                                    type="text"
                                    disabled
                                    value={moment(user.createdAt).format(
                                      "DD/MM/YYYY",
                                    )}
                                    className="input-answer"
                                  />
                                </div>{" "}
                                <br />
                                <div className={`p-3 `}>
                                  <label htmlFor="">
                                    Porcentagem de acertos:{" "}
                                  </label>
                                  <input
                                    type="text"
                                    disabled
                                    value={`${percent.toFixed(1)}%`}
                                    className={`input-answer ${
                                      percent >= 100
                                        ? "percent-corret"
                                        : "percent-not-correct"
                                    }`}
                                  />
                                </div>{" "}
                              </div>
                              <br />
                              <br />
                            </>
                          );
                        })}
                      </>
                    )}
                  {this.state.users <= 0 && (
                    <>
                      <div>Nenhuma resposta encontrada.</div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const WelcomePageLink = (props) => (
  <Link className="menu-link" to={routes.GET_CODIGO_CONDUTA}>
    <FontAwesomeIcon icon={faTachometerAlt} />
    <span className="sidebarText">{i18next.t("welcome")}</span>
  </Link>
);

GetCodigoConduta.contextTypes = {
  authUser: PropTypes.object,
};

const authCondition = (authUser) => !!authUser;

export default withAuthorization(authCondition)(GetCodigoConduta);

export { WelcomePageLink };
