import React, { Component } from 'react';
import * as routes from '../../routes';

export default class StartPage extends Component {
  componentWillMount = () => {
    const authUser = localStorage.getItem('userType');

    this.props.history.push(routes.SIGIN_CLIENT);
  };

  render() {
    return <div />;
  }
}
