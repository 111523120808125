// // ADMIN MASTER
// export const START_ADMIN = '/';
// export const SIGN_UP_ADMIN = '/admin/signup';
// export const SIGN_IN_ADMIN = '/admin/signin';
// export const PASSWORD_FORGET_ADMIN = '/admin/passwordForget';
// export const DASHBOARD_ADMIN = '/admin/dashboard';
// export const LOGOUT_ADMIN = '/admin/logout';
// export const CLIENTS_ADMIN = '/admin/clients';
// export const ADMINS = '/admin/administrators';
// export const CREATE_CLIENT = '/admin/createClient';
// export const REGISTER_CLIENT = '/admin/registerClient';
// export const EDIT_CLIENT = '/admin/editClient';
// export const EDIT_ADMIN = '/admin/editAdmin';
// export const COMERCIAL_ADMIN = '/admin/comercialAdmin';
// export const LEADS_ADMIN = '/admin/leads';
// export const PROSPECT_LEADS_ADMIN = '/admin/prospectLeads';
// export const TERMS_ADMIN = '/admin/termsAdmin';
// export const PREVIEW_TEXT_ADMIN = '/admin/previewTextAdmin';
// export const EDIT_TEXT_ADMIN = '/admin/editText';
// export const EMAILS_ADMIN = '/admin/Emails';
// export const NEW_EMAIL_ADMIN = '/admin/NewEmail';
// export const WALLETS = '/admin/wallets';
// export const EDIT_WALLET = '/admin/editWallet';
// export const DETAILS_BANK_TRANSFER_REQUEST =
//   '/admin/detailsBankTransferRequest';
// export const PLAYERS_ADMIN = '/admin/players';

// // ADMIN CLIENT

export const SIGIN_CLIENT = "/";
export const SIGNOUT_CLIENT = "/client/signout";

export const WELCOME_CLIENT = "/client/welcome";
export const GET_CODIGO_CONDUTA = "/client/codigo_conduta";
export const DASHBOARD = "/client/dashboard";
export const PROFILE_CLIENT = "/client/profileClient";
export const PROFILE_USER = "/client/profileUser";
export const PASSWORD_FORGET_CLIENT = "/client/passwordForget";

// export const PLAYER_RANKING = '/client/playerRanking';
// export const PONTUATION_RULES = '/client/pontuationRules';
// export const RANKING_INDICATIONS = '/client/rankingIndications';

// export const WALLET_CLIENT = '/client/wallet';

// export const WITHOUT_PERMISSION = 'client/withoutPermission';

// // Rotas de dashboard
export const QUIZ = "/client/quiz";
export const QUIZ_EXPEDICAO = "/client/conduta";
