import PropTypes from "prop-types";
import React from "react";
import api from "../../../services/api";

// import { firebase, db } from '../../../firebase';

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: null,
        clientData: null,
        userData: null,
      };
    }

    componentDidMount() {
      const admin = localStorage.getItem("admin");

      if (admin) {
        this.getAuthAdmin(admin);
      }
      // firebase.auth.onAuthStateChanged((authUser) => {
      //   if (authUser) {
      //     this.getAuthClient(authUser);
      //   } else {
      //     this.setState({ authUser: null, clientData: null });
      //   }
      // });
      // if (window.location.pathname.includes('/client/')) {
      //   firebase.auth.onAuthStateChanged(authUser => {
      //     if (authUser) {
      //       this.getAuthClient(authUser);
      //     }
      //     // else {
      //     //   this.setState({ authUser: null, clientData: null });
      //     // }
      //   });
      // } else {
      //   firebase.auth.onAuthStateChanged(authUser => {
      //     if (authUser) {
      //       this.getAuthAdmin(authUser);
      //     } else {
      //       this.setState({ authUser: null, clientData: null });
      //     }
      //   });
      // }
    }

    getChildContext() {
      return {
        authUser: this.state.authUser,
        clientData: this.state.clientData,
        userData: this.state.userData,
      };
    }

    getAuthClient(authUser) {
      // db.getSigninClient(authUser.uid).then((userData) => {
      //   let snapClient = userData.val();
      //   console.log('snapClient', snapClient);
      //   this.setState({
      //     authUser: authUser,
      //     clientData: snapClient,
      //     userData: snapClient,
      //   });
      // });
    }

    async getAuthAdmin(authUser) {
      const user = await api.get("/auth/admin", {
        params: {
          id: authUser,
        },
      });
      // db.getSigninClient(authUser.uid).then((clientData) => {
      this.setState({ authUser: user.data, clientData: user.data });
      // });
    }

    updateGamificationTypeOnContext = (gamificationTypeSnap) => {
      const { clientData } = this.state;
      clientData.gamificationType = gamificationTypeSnap.val();
      this.setState({ clientData });
    };

    render() {
      return <Component {...this.props} />;
    }
  }

  WithAuthentication.childContextTypes = {
    authUser: PropTypes.object,
    clientData: PropTypes.object,
    userData: PropTypes.object,
  };

  return WithAuthentication;
};

export default withAuthentication;
