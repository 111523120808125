import Color from "color";
import { css } from "glamor";
import $ from "jquery";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import { withNamespaces } from "react-i18next";

import * as routes from "../../../routes";

import * as GoogleIcons from "react-icons/md";
import LogoOQ from "../../Images/logo_cazanga_branco.png";
import SideBarItem from "./SideBarItem";

class SideBar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.translate = props.t;
  }

  getInitialState() {
    const inititalState = {
      companyName: "",
      companyEmail: "",
      currentPage: "",
      loadingLogo: true,
      // logoURL: SideBarLogo,
      logoURL: "",
      sidebarStatus: false,
      primaryColor: "#000000",
      secondaryColor: "#fff",
      systemFont: "Gotham Rounded",
      isGamified: false,
      gamificationType: "points",
    };

    return inititalState;
  }

  componentDidMount() {
    // this.getClient();

    let pathname = window.location.pathname;
    pathname = pathname.replace("/", "/");

    const sidebarStatus = localStorage.getItem("sideBarStatus") === "true";
    this.setState({ sidebarStatus });

    $("#sidebarCollapse").on("click", () => {
      this.toggleSidebar();
    });

    this.setState({ currentPage: pathname });
  }

  toggleSidebar() {
    const newSidebarState = !this.state.sidebarStatus;
    localStorage.setItem("sideBarStatus", newSidebarState);
    this.setState({ sidebarStatus: newSidebarState });
  }

  getIsActive(route) {
    const { currentPage } = this.state;
    return String(currentPage).includes(route);
  }

  getSidebarListCSS() {
    const { primaryColor, secondaryColor } = this.state;
    const rule = css({
      ":hover": {
        background: `${secondaryColor} !important`,
        color: `${primaryColor} !important`,
      },
      ".active": {
        borderRight: `10px solid ${primaryColor} !important`,
        background: `${secondaryColor} !important`,
        color: `${primaryColor} !important`,
      },
    });

    return rule;
  }

  shortString(str, lengthStr) {
    // correção de verificação
    if (str && str.length > lengthStr) {
      return str.substr(0, lengthStr) + "...";
    }
    return str;
  }

  render() {
    const {
      currentPage,
      favIcon,
      systemFont,
      primaryColor,
      secondaryColor,
    } = this.state;

    const { isGamified, gamificationType } = this.state;

    return (
      <>
        <Helmet>
          <style type="text/css">
            {`
              :root{
                --primary-color: ${primaryColor};
                --primary-color-dark: ${Color(primaryColor).darken(0.45)};
                --secondary-color: ${secondaryColor};
                --oq-font-regular: '${systemFont} Regular';
                --oq-font-bold: '${systemFont} Bold';
                --oq-font-book: '${systemFont} Book';
                --oq-font-medium: '${systemFont} Medium';
                --oq-font-light: '${systemFont} Light';
              }
          `}
          </style>
          <link rel="shortcut icon" href={favIcon} />
        </Helmet>
        <div className="oq-sidebar-area oq-z-depth-1">
          {/* <div className="oq-sidebar-business-info">
            <img src={favIcon} alt="" />
            <div className="content">
              <span id="company-name">
                {this.shortString(this.state.companyName, 18)}
              </span>
              <span id="company-email">
                {this.shortString(
                  this.context &&
                    this.context.authUser &&
                    this.context.authUser.email,
                  25,
                )}
              </span>
              </div>
            </div> */}
          {/* <SignOutClientLink /> */}
          <ul className="oq-sidebar-menu">
            {/* <SideBarItem
              isActive={dashboardIsOpened ? 'active' : ''}
              isVisible={true}
              link={routes.DASHBOARD_CLIENT}
              icon={<GoogleIcons.MdDashboard />}
              label="Dashboard"
            /> */}
            {/* <SideBarItem
              isActive={currentPage === routes.ACCESS_GROUPS ? 'active' : ''}
              isVisible={true}
              link={routes.ACCESS_GROUPS}
              icon={<GoogleIcons.MdGroupWork />}
              label="Grupos de acesso"
            />
            <SideBarItem
              isActive={currentPage === routes.USERS ? 'active' : ''}
              isVisible={true}
              link={routes.USERS}
              icon={<GoogleIcons.MdGroup />}
              label="Usuários"
            />

            <SideBarItem
              isActive={currentPage === routes.PROFILE_CLIENT ? 'active' : ''}
              isVisible={true}
              link={routes.PROFILE_CLIENT}
              icon={<GoogleIcons.MdBusiness />}
              label="Empresa"
            />
            <SideBarItem
              isActive={currentPage === routes.PROFILE_USER ? 'active' : ''}
              isVisible={true}
              link={routes.PROFILE_USER}
              icon={<GoogleIcons.MdAccountCircle />}
              label="Meu Perfil"
            />
            <SideBarItem
              isActive={
                currentPage === routes.LANDING_PAGE_LOGIN ? 'active' : ''
              }
              isVisible={true}
              link={routes.LANDING_PAGE_LOGIN}
              icon={<GoogleIcons.MdWeb />}
              label="Landing Page"
            />
            <SideBarItem
              isActive={
                currentPage === routes.GAMIFICATION_CLIENT ? 'active' : ''
              }
              isVisible={true}
              link={routes.GAMIFICATION_CLIENT}
              icon={<GoogleIcons.MdGrade />}
              label="Gamification"
            />
            <Collapsible
              isVisible={
                isGamified &&
                gamificationType &&
                gamificationType !== 'goalsLevels'
              }
              isActive={premiumsIsOpened ? 'active' : ''}
              label="Resgate de prêmios"
              icon={<GoogleIcons.MdCardGiftcard />}
            >
              <ul>
                <SideBarItem
                  isActive={
                    this.getIsActive(routes.PREMIUMS) ||
                    this.getIsActive(routes.NEW_PREMIUM) ||
                    this.getIsActive(routes.EDIT_PREMIUM)
                      ? 'active'
                      : ''
                  }
                  isVisible={
                    isGamified &&
                    gamificationType &&
                    isGamified === 'false' &&
                    gamificationType !== 'goalsLevels'
                  }
                  link={routes.PREMIUMS}
                  label="Prêmios"
                />
                <SideBarItem
                  isActive={
                    this.getIsActive(routes.PURCHASE_UPLOADS) ? 'active' : ''
                  }
                  isVisible={true}
                  link={routes.PURCHASE_UPLOADS}
                  label="Compras"
                />
                <SideBarItem
                  isActive={this.getIsActive(routes.RESCUES) ? 'active' : ''}
                  isVisible={true}
                  link={routes.RESCUES}
                  label="Resgates"
                />
              </ul>
            </Collapsible>
            <SideBarItem
              isVisible={
                isGamified &&
                gamificationType &&
                isGamified === 'true' &&
                gamificationType !== 'goalsLevels'
              }
              isActive={this.getIsActive(routes.SEASONS) ? 'active' : ''}
              link={routes.SEASONS}
              icon={<GoogleIcons.MdDonutLarge />}
              label="Temporadas"
            />
            <Collapsible
              isVisible={true}
              isActive={challengesIsOpened ? 'active' : ''}
              label="Publicações"
              icon={<GoogleIcons.MdFlag />}
            >
              <ul>
                <SideBarItem
                  isVisible={
                    gamificationType && gamificationType !== 'goalsLevels'
                  }
                  isActive={
                    this.getIsActive(routes.ACTIONS_VIRTUALS) ||
                    this.getIsActive(routes.EDIT_ACTION_VIRTUAL) ||
                    this.getIsActive(routes.CREATE_ACTION_VIRTUAL)
                      ? 'active'
                      : ''
                  }
                  link={routes.ACTIONS_VIRTUALS}
                  label="Virtuais"
                />
                <SideBarItem
                  isVisible={
                    gamificationType && gamificationType !== 'goalsLevels'
                  }
                  isActive={
                    this.getIsActive(routes.ACTIONS_OMNICHANNELS) ||
                    this.getIsActive(routes.EDIT_ACTION_OMNICHANNEL) ||
                    this.getIsActive(routes.CREATE_ACTION_OMNICHANNEL)
                      ? 'active'
                      : ''
                  }
                  link={routes.ACTIONS_OMNICHANNELS}
                  label="Presenciais"
                />
                <SideBarItem
                  isVisible={
                    gamificationType && gamificationType !== 'goalsLevels'
                  }
                  isActive={
                    this.getIsActive(routes.EDIT_ACTION_QUIZ) ||
                    this.getIsActive(routes.CREATE_ACTION_QUIZ)
                      ? 'active'
                      : ''
                  }
                  link={routes.ACTIONS_QUIZ}
                  label="Quiz"
                />
                <SideBarItem
                  isVisible={
                    gamificationType && gamificationType !== 'goalsLevels'
                  }
                  isActive={
                    this.getIsActive(routes.ACTIONS_INFORMATIONALS) ||
                    this.getIsActive(routes.CREATE_ACTION_INFORMATIONAL)
                      ? 'active'
                      : ''
                  }
                  link={routes.ACTIONS_INFORMATIONALS}
                  label="Venda direta"
                />

                <SideBarItem
                  isVisible={
                    gamificationType && gamificationType !== 'goalsLevels'
                  }
                  isActive={
                    this.getIsActive(routes.RESEARCHAWARD_CLIENT) ||
                    this.getIsActive(routes.CREATE_RESEARCHAWARD_CLIENT)
                      ? 'active'
                      : ''
                  }
                  link={routes.RESEARCHAWARD_CLIENT}
                  label="Pesquisas/NPS"
                />
                <SideBarItem
                  isVisible={
                    gamificationType &&
                    gamificationType !== 'goalsLevels' &&
                    this.state &&
                    this.state.isTeamCompetition === 'true' &&
                    this.state.isTeamCompetition !== undefined
                  }
                  isActive={
                    this.getIsActive(routes.ACTIONS_INVITES) ||
                    this.getIsActive(routes.ACTIONS_INVITES)
                      ? 'active'
                      : ''
                  }
                  link={routes.ACTIONS_INVITES}
                  label="Convite"
                />
              </ul>
            </Collapsible>
            <SideBarItem
              isVisible={gamificationType && gamificationType !== 'goalsLevels'}
              isActive={this.getIsActive(routes.CONVERSIONS) ? 'active' : ''}
              icon={<GoogleIcons.MdExposurePlus1 />}
              link={routes.CONVERSIONS}
              label="Pontos extras"
            />
            <Collapsible
              isVisible={true}
              isActive={crmIsOpened ? 'active' : ''}
              label="Comunicação"
              icon={<GoogleIcons.MdAddAlert />}
            >
              <ul>
                <SideBarItem
                  isVisible={true}
                  isActive={
                    this.getIsActive(routes.MESSAGES) ||
                    this.getIsActive(routes.NEW_MESSAGE)
                      ? 'active'
                      : ''
                  }
                  link={routes.MESSAGES}
                  label="Notificações"
                />
                <SideBarItem
                  isVisible={
                    this.context.clientData &&
                    this.context.clientData.clientUrl !== 'perplan'
                  }
                  isActive={
                    this.getIsActive(routes.EMAILS) ||
                    this.getIsActive(routes.NEW_EMAIL)
                      ? 'active'
                      : ''
                  }
                  link={routes.EMAILS}
                  label="E-mails"
                />

                <SideBarItem
                  isVisible={true}
                  isActive={
                    this.getIsActive(routes.BANNERS) ||
                    this.getIsActive(routes.NEW_BANNER)
                      ? 'active'
                      : ''
                  }
                  link={routes.BANNERS}
                  label="Banners"
                />
                <SideBarItem
                  isVisible={true}
                  isActive={
                    this.getIsActive(routes.WHATSAPP_BUSINESS) ? 'active' : ''
                  }
                  link={routes.WHATSAPP_BUSINESS}
                  label="WhatsApp Business"
                />
              </ul>
            </Collapsible>
            <SideBarItem
              isVisible={gamificationType && gamificationType !== 'goalsLevels'}
              isActive={currentPage === routes.BENEFITS_CLIENT ? 'active' : ''}
              icon={<GoogleIcons.MdLocalPlay />}
              link={routes.BENEFITS_CLIENT}
              label="Benefícios"
            />
            <SideBarItem
              isVisible={gamificationType && gamificationType !== 'goalsLevels'}
              isActive={currentPage === routes.PUBLIC_TARGET ? 'active' : ''}
              icon={<GoogleIcons.MdSync />}
              link={routes.PUBLIC_TARGET}
              label="Leads/Conversões"
            />

            {this.state &&
            this.state.isTeamCompetition &&
            this.state.isTeamCompetition === 'true' ? (
              <Collapsible
                isVisible={
                  this.state &&
                  this.state.isTeamCompetition &&
                  this.state.isTeamCompetition === 'true'
                }
                isActive={playerOpened ? 'active' : ''}
                label="Players"
                icon={<GoogleIcons.MdList />}
              >
                <ul>
                  <SideBarItem
                    isVisible={true}
                    isActive={
                      this.getIsActive(routes.PLAYERS) ||
                      this.getIsActive(routes.EDIT_PLAYER)
                        ? 'active'
                        : ''
                    }
                    link={routes.PLAYERS}
                    label="Membros das Equipes"
                  />
                  <SideBarItem
                    isVisible={true}
                    isActive={
                      this.getIsActive(routes.INFLUENCERS) ? 'active' : ''
                    }
                    link={routes.INFLUENCERS}
                    label="Líderes de Equipe"
                  />
                </ul>
              </Collapsible>
            ) : (
              <SideBarItem
                isVisible={true}
                isActive={
                  this.getIsActive(routes.PLAYERS) ||
                  this.getIsActive(routes.EDIT_PLAYER)
                    ? 'active'
                    : ''
                }
                link={routes.PLAYERS}
                label="Players"
                icon={<GoogleIcons.MdList />}
              />
            )}

            <SideBarItem
              isVisible={true}
              isActive={currentPage === routes.WALLET_CLIENT ? 'active' : ''}
              link={routes.WALLET_CLIENT}
              label="Financeiro"
              icon={<GoogleIcons.MdAccountBalanceWallet />}
            />

            <SideBarItem
              isVisible={true}
              isActive={currentPage === routes.CONFIG ? 'active' : ''}
              link={routes.CONFIG}
              label="Configurações"
              icon={<GoogleIcons.MdSettings />}
            />
            <SideBarItem
              isVisible={true}
              isActive={
                this.getIsActive(routes.TERMS_CLIENT) ||
                this.getIsActive(routes.PREVIEW_TEXT) ||
                this.getIsActive(routes.EDIT_TEXT)
                  ? 'active'
                  : ''
              }
              link={routes.TERMS_CLIENT}
              label="Condições gerais"
              icon={<GoogleIcons.MdGavel />}
            />
            <SideBarItem
              isVisible={true}
              isActive={currentPage === routes.EMAIL_TEMPLATES ? 'active' : ''}
              link={routes.EMAIL_TEMPLATES}
              label="E-mail de boas vindas"
              icon={<GoogleIcons.MdEmail />}
            /> */}
            {/* <li>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/TermsAndPolitics%2FPoli%CC%81tica%20de%20Privacidade_OQ%20MIDIA%20DIGITAL%20JULHO%202020.docx.pdf?alt=media&token=73c99224-963a-40c8-b385-618bb2cc5122"
                target="_blank"
              >
                <i>
                  <GoogleIcons.MdHttps />
                </i>
                <span>Política de privacidade</span>
              </a>
            </li> */}
            {/* <li>
              <a
                href="https://oqdigital.atlassian.net/servicedesk/customer/portal/1"
                target="_blank"
              >
                <i>
                  <GoogleIcons.MdSmsFailed />
                </i>
                <span>Suporte OQ Digital</span>
              </a>
            </li> */}

            <li className="oq-sidebar-footer">
              <div>
                <img src={LogoOQ} />
              </div>
              <span>Todos os direitos reservados</span>
              <a href="https://cazanga.com.br/" target="_blank">
                cazanga.com.br
              </a>
            </li>
            <br />
            <br />
            <SideBarItem
              isVisible={true}
              isActive={this.getIsActive(routes.WELCOME_CLIENT) ? "active" : ""}
              link={routes.WELCOME_CLIENT}
              icon={<GoogleIcons.MdFindInPage />}
              label="Buscar Motorista"
            />
            <SideBarItem
              isVisible={true}
              isActive={this.getIsActive(routes.QUIZ) ? "active" : ""}
              link={routes.QUIZ}
              icon={<GoogleIcons.MdQuestionAnswer />}
              label="Perguntas Motorista Qualificado"
            />
            {this.context.clientData?.master && (
              <SideBarItem
                isVisible={true}
                isActive={
                  this.getIsActive(routes.GET_CODIGO_CONDUTA) ? "active" : ""
                }
                link={routes.GET_CODIGO_CONDUTA}
                icon={<GoogleIcons.MdFindInPage />}
                label="Buscar Código de Conduta"
              />
            )}
            {this.context.clientData?.master && (
              <SideBarItem
                isVisible={true}
                isActive={
                  this.getIsActive(routes.QUIZ_EXPEDICAO) ? "active" : ""
                }
                link={routes.QUIZ_EXPEDICAO}
                icon={<GoogleIcons.MdQuestionAnswer />}
                label="Perguntas Código de Conduta"
              />
            )}
          </ul>
        </div>
      </>
    );
  }
}

SideBar.contextTypes = {
  authUser: PropTypes.object,
  clientData: PropTypes.object,
  userData: PropTypes.object,
};

SideBar = withNamespaces()(SideBar);
export default SideBar;

export { SideBar };
