import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import { ToastContainer } from "react-toastify";

import { Notify } from "../../../components/Notify";
import * as routes from "../../../routes";

import api from "../../../services/api";

const SignInClientPage = ({ history }) => <SignInForm history={history} />;

class SignInForm extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
  }

  getInitialState() {
    const initialState = {
      email: "",
      password: "",
      error: null,
      result: null,
      loginStatus: "ENTRAR",
      processing: false,
      isLoadingClient: false,
    };
    return initialState;
  }

  async componentDidMount() {
    const { history } = this.props;

    // const methodGet = await getClientData();
    // // console.log('methodGet', methodGet);
    // const isUrl = methodGet.type === 'activeDomain';

    // const authPlayer = auth.getAuthPlayer();
    // // console.log('authPlayer', authPlayer);

    // if (authPlayer && authPlayer.uid) {
    //   history.push(routes.WELCOME_CLIENT);
    // } else {
    //   this.setState({ signingIn: false });
    //   // console.log('aaa', methodGet.link, isUrl);

    //   await this.getClientDataByAlias(methodGet.link, isUrl);
    // }
  }

  async onSubmit(event) {
    event.preventDefault();
    this.setState({ processing: true, loginStatus: "Entrando ..." });

    const { email, password } = this.state;
    const data = {
      email,
      password,
    };

    const { history } = this.props;

    this.setState({ error: null });
    const res = await api
      .post("/auth/authenticate", data)

      .then((snapshot) => {
        localStorage.setItem("admin", snapshot.data.user._id);
        history.push(routes.WELCOME_CLIENT);
      })
      .catch((error) => {
        console.log("doSignInWithEmailAndPassword error", error);
        this.setState({ signingIn: false });

        let errorMsg = "";
        if (error.code === "auth/wrong-password") {
          errorMsg = "E-mail ou senha incorretos.";
        } else if (
          error.code === "auth/account-exists-with-different-credential"
        ) {
          errorMsg = "Já existe uma conta com o e-mail informado.";
        } else if (
          error.message ===
          "Você não está autorizado a fazer login nesse momento. Contate o administrador para mais informações."
        ) {
          errorMsg = "E-mail não cadastrado para este cliente.";
        } else {
          errorMsg = "E-mail ou senha incorretos.";
        }

        Notify(errorMsg, "error");
        this.setState({
          processing: false,
          loginStatus: "ENTRAR",
          error,
        });
      });
  }

  errorReceiving(error) {
    this.setState({
      processing: false,
      loginStatus: "ENTRAR",
      error,
    });
  }

  shortString(str, lengthStr) {
    if (str.length > lengthStr) {
      return str.substr(0, lengthStr) + "...";
    }
    return str;
  }

  render() {
    // console.log('statee-->', this.state);
    const { email, password, loginStatus, processing } = this.state;

    const animationDuration = "5s";
    const animationDelay = ["5s", "10s", "20s"];

    const company = this.state.companyName ? this.state.companyName : "Empresa";
    const logoCompany = this.state.logoURL ? this.state.favIcon : "";

    return (
      <>
        <ToastContainer />

        <div className="oq-login">
          {/* <VideoBg /> */}
          <div className="oq-login-opacity">
            <div className="content-left center"></div>
            <div className="content-right center">
              <div className="oq-login-content">
                <form onSubmit={(event) => this.onSubmit(event)}>
                  <div
                    className="oq-login-card animate__animated animate__fadeIn"
                    style={{
                      animationDuration: animationDuration,
                    }}
                  >
                    <div className="oq-login-card-header">
                      <div
                        style={{
                          textAlign: "center",
                        }}
                      ></div>

                      <p className="oq-font-book">Faça login.</p>
                    </div>
                    <div className="oq-login-card-content">
                      <div className="oq-input-field">
                        <label htmlFor="inputEmail">E-mail da conta</label>
                        <input
                          disabled={this.state.isLoadingClient}
                          type="email"
                          id="inputEmail"
                          className="login-placeholder form-control"
                          placeholder="Entre com seu e-mail"
                          value={email}
                          required
                          onChange={(event) =>
                            this.setState({
                              email: event.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="oq-input-field">
                        <label htmlFor="inputPassword">Sua senha</label>
                        <input
                          disabled={this.state.isLoadingClient}
                          type="password"
                          id="inputPassword"
                          className="login-placeholder form-control"
                          placeholder="Senha"
                          value={password}
                          required
                          minLength={6}
                          onChange={(event) =>
                            this.setState({
                              password: event.target.value,
                            })
                          }
                        />
                        <br />
                        {this.state.clientActive && (
                          <Link to={routes.PASSWORD_FORGET_CLIENT}>
                            Esqueceu a senha?
                          </Link>
                        )}
                      </div>
                    </div>
                    <div className="oq-login-card-action">
                      <button
                        disabled={this.state.isLoadingClient}
                        className="oq-login-btn"
                        type="submit"
                      >
                        {loginStatus}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(SignInClientPage);

export { SignInForm };
