import React from "react";
import { Route, Switch } from "react-router-dom";
import * as routes from "../../routes";
// Admin Client

import Dashboard from "../../AdminClient/pages/Dashboard";
import GetCodigoConduta from "../../AdminClient/pages/GetCodigoConduta";
import Quiz from "../../AdminClient/pages/Quiz";
import QuizExpedicao from "../../AdminClient/pages/QuizExpedicao";
import SignInClientPage from "../../AdminClient/pages/SignIn";
import { SignOutClientPage } from "../../AdminClient/pages/SignOut";
import Welcome from "../../AdminClient/pages/Welcome";
import NotFoundPage from "../../Config/NotFound";

const RoutesClient = (props) => {
  // const context = useUserData();
  // const { groupPermissions, userData } = context.user;
  // console.log('to nas rotas dos clientes', context);
  return (
    <Switch>
      {/** INÍCIO Admin Client */}
      <Route
        exact
        path={`${routes.SIGIN_CLIENT}/`}
        component={SignInClientPage}
      />

      <Route
        exact
        path={routes.SIGNOUT_CLIENT}
        component={() => <SignOutClientPage />}
      />

      <Route
        exact
        path={routes.WELCOME_CLIENT}
        component={(props) => <Welcome {...props} />}
      />
      <Route
        exact
        path={routes.GET_CODIGO_CONDUTA}
        component={(props) => <GetCodigoConduta {...props} />}
      />
      <Route
        exact
        path={routes.QUIZ_EXPEDICAO}
        component={(props) => <QuizExpedicao {...props} />}
      />
      <Route
        exact
        path={routes.QUIZ}
        component={(props) => <Quiz {...props} />}
      />
      <Route
        exact
        path={routes.DASHBOARD}
        component={(props) => <Dashboard {...props} />}
      />

      {/* Rotas antigas ou não existentes */}

      {/* <Route
        exact
        path={routes.COMUNICATION_CLIENT}
        component={() => <ComunicationClientPage />}
      />

      <Route
        exact
        path={routes.REPORTS_CLIENT}
        component={() => <ReportsClientPage />}
      /> */}

      {/* NOT FOUND */}
      <Route component={(props) => <NotFoundPage {...props} />} />

      {/** FIM Admin Client */}
    </Switch>
  );
};

export default RoutesClient;
